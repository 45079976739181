/* eslint-disable jsx-a11y/anchor-has-content */
import {Link, useParams, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import { useEffect, useState } from 'react';
import useAuth from '../../Hooks/useAuth';
import jwtDecode from 'jwt-decode';
import Select from 'react-select';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

//Editor
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const DELETE_PROFILE_URL = '/alum/delete/';
const EDIT_PROFILE_URL = '/alum/edit/';
const VIEW_PROFILE_URL = '/alum/view/';
const UPLOAD_FILE_URL = '/singleUpload';
const DELETE_FILE_URL = '/fileDelete/';

function Alumus({PF}) {
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
    const [profile, setProfile] = useState({});  
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [classOf, setClassOf] = useState('');
    const [bio, setBio] = useState('');
    const [updateMode, setUpdateMode] = useState(false);
    const [file, setFile] = useState(null);
    const navigate = useNavigate();
    let {id} = useParams();
  
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        axiosPrivate.get(VIEW_PROFILE_URL + id).then((response)=>{
            if(isMounted){
                setProfile(response.data);
                setName(response.data.name);
                setEmail(response.data.email);
                setPhone(response.data.phone);
                setClassOf(response.data.classOf);
                setBio(response.data.alumDetails);
            }
        }).catch((error) => {
            //Handle Errors Coming out of this
        });
        return () => { isMounted = false; controller.abort();  }
        // eslint-disable-next-line
    }, [id]);

    const deleteProfile = async(id) => {
        try {
            axiosPrivate.post(DELETE_FILE_URL + profile.picture).then(()=>{
                console.log('Old image deleted');
            }).catch(function(error){
                console.log(error.config);
            });

            axiosPrivate.delete(DELETE_PROFILE_URL + id).then(()=>{
                alert('Alumnus Profile Deleted');
                navigate('/alum');
            }).catch(function(error){
                console.log(error.config);
            });               
        } catch(error){
            console.log(error);
        }
    };

    const onbio = (value) => {
        setBio(value);
    } 

    const updateProfile = async(e) => {
        e.preventDefault();
        const updatedProfile = {name, email, phone, classOf, details: bio, userId: authDecode.user.id, id: profile.id};
        const profileId = profile.id;

        if(file){
            if (!file.name.match(/\.(jpg|JPG|jpeg|JPEG|png|PNG)$/)) {
                alert("Wrong file type. Upload a jpg, jpeg or png");
                navigate(0);
            }else if (file.size > 700000) {
                alert("File is too large. Upload file less than 700kb");
                navigate(0);
            }else {
                axiosPrivate.post(DELETE_FILE_URL + profile.picture).then(()=>{
                    console.log('Old image deleted');
                }).catch(function(error){
                    console.log(error.config);
                });

                const formData = new FormData();
                const filename = name + '_' + Date.now() + '_' + file.name;            
                formData.append("name", filename);
                formData.append("file", file);
                updatedProfile.picture = filename;
        
                try{
                    await axiosPrivate.post(UPLOAD_FILE_URL, formData);
                    alert('Image Posted');
                }catch(err){
                    console.log(err);
                }
            }
        }
        
        try {
            axiosPrivate.put(EDIT_PROFILE_URL + profileId, updatedProfile).then(()=>{
                alert('Alumni Profile Edited');
                setUpdateMode(false);
                window.location.reload();
            });   
        }catch (error) {
            console.log(error);  
        }
    }

    const date = new Date();
    const year = date.getFullYear();
    let classYear = [];

    for (var i = 2002; i <= year-1; i++) {
        classYear.push({label: i, value: i})
    }

    return (
        <>
            <section id="teachers-singel" className="pt-30 pb-120 gray-bg">
                <div className="container">    
                    { updateMode ? 
                        <div className='main-form'>
                            <form className="p-2" onSubmit={updateProfile} data-toggle="validator">
                                <div className="row">
                                    <div className="col-lg-4 col-md-8">
                                        <div className="teachers-left mt-50">
                                            <div className="hero">
                                                { file ? (
                                                    <img src={URL.createObjectURL(file)} alt={name} />
                                                ): (
                                                    profile?.picture && <img src={PF + profile?.picture} alt={name} />
                                                )}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="fileInput">
                                                    <br /><h5>Change Profile Image: <i className="fa fa-recycle"></i></h5>
                                                </label>
                                                <input type="file" id="fileInput" style={{display: "none"}} onChange={(e)=>setFile(e.target.files[0])} />
                                            </div>
                                            <div className="form-group section-title">
                                                <h5>Name</h5>
                                                <input type="text" value={name} autoFocus={true} className="form-control p-2" required="required" onChange={(e)=>setName(e.target.value)} />
                                            </div>
                                            <div className="form-group section-title">
                                                <br /><h5>Class of </h5>
                                                <Select defaultInputValue={classOf} options={classYear} onChange={(e)=>setClassOf(e)} />
                                            </div>
                                            <div className="form-group section-title">
                                                <br /><h5><i className="icon fa fa-envelope-o" /> Email</h5>
                                                <input type="text" value={email} autoFocus={true} className="form-control p-2" required="required" onChange={(e)=>setEmail(e.target.value)} />
                                            </div>
                                            <div className="form-group section-title">
                                                <br /><h5><i className="fa fa-mobile" /> Phone</h5>
                                                <PhoneInput defaultCountry='UG' value={phone} onChange={setPhone}/>
                                                <p className="small">*Example: 772 777 777 or 705 123 456</p> 
                                                {/* <input type="text" value={phone} autoFocus={true} className="form-control p-2" required="required" onChange={(e)=>setPhone(e.target.value)} /> */}
                                            </div>
                                        </div> { /* <!-- teachers left --> */} 
                                    </div>
                                    <div className="col-lg-8 mt-50">
                                        <div className='form-group section-title'>
                                            <h5>Bio:</h5>
                                            <ReactQuill theme="snow" rows="10" value={bio} onChange={onbio} />
                                        </div>
                                        <br />
                                        <p className="form-message"></p>
                                        <div>
                                            <div className="singel-form">
                                                <button type="submit" className="main-btn disabled" disabled={!name || !classOf || !email || !phone || !bio}>Send</button>
                                                &nbsp;&nbsp;&nbsp;
                                                <Link className="main-btn" to='#cancel' onClick={(e)=>setUpdateMode(false)} >Cancel</Link> 
                                            </div> { /* <!-- singel form --> */ }
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    :                    
                        <div className="row justify-content-center">
                            <div className="col-lg-4 col-md-8">
                                <div className="teachers-left mt-30">
                                    <div className="hero">
                                        { file ? (
                                            <img src={URL.createObjectURL(file)} alt={name} />
                                        ): (
                                            profile?.picture && <img src={PF + profile?.picture} alt={name} />
                                        )}
                                    </div>
                                    <div className='blog-details'>
                                        <div className="cont name">
                                            <h6>{profile?.name}</h6><br />
                                            <b>Class of:</b> {classOf}
                                            { (authDecode && authDecode?.user?.role >=4) &&
                                                <ul>
                                                    <li><Link to='#email' onClick={(e)=>{ window.location = `mailto:${email}`}}><i className="fa fa-envelope" aria-hidden="true" /> {email}</Link></li><br/>
                                                    <li><Link to='#mobile'><i className="fa fa-phone" aria-hidden="true" /></Link> {phone}</li><br/><br/>
                                                    <li><Link to='#edit'><i className="fa fa-edit" aria-hidden="true" onClick={() => setUpdateMode(true)}/></Link>Edit</li>
                                                    <li><Link to='#delete'><i className="fa fa-trash" aria-hidden="true" onClick={() => deleteProfile(profile.id)} /></Link>Delete</li>
                                                </ul>
                                            }
                                        </div>
                                   </div>
                                </div> { /* <!-- teachers left --> */}
                            </div>
                            <div className="col-lg-8">
                                <div className="teachers-right mt-30">
                                    <ul className="nav nav-justified" id="myTab" role="tablist">
                                        <li className="nav-item">
                                            <Link className="active" id="dashboard-tab" data-toggle="tab" to="#dashboard" role="tab" aria-controls="dashboard" aria-selected="true">Bio</Link>
                                        </li>
                                    </ul> { /* <!-- nav --> */}
                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane fade show active" id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                                            <div className="dashboard-cont pt-20" dangerouslySetInnerHTML={{ __html: bio}} style={{whiteSpace: "pre-line"}} />
                                        </div>
                                    </div> { /* <!-- tab content --> */}
                                </div> { /* <!-- teachers right --> */}
                            </div>
                        </div> // <!-- row --> 
                    }
                </div> { /* <!-- container --> */}
            </section>
        </>
    )
}

export default Alumus
