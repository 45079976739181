import RightSideBar from '../Components/RightSideBar';
import {Routes, Route} from 'react-router-dom';
import ViewAlum from './Alum/AlumProfiles';
import Alumus from './Alum/Alumus';

function Alum ({PF}) {
  return (
    <div className='container-fluid pt-5 mb-3'>
      <div className="container">
        <div className='row'>
          <Routes>
            <Route exact path='/' element={<ViewAlum PF={PF}/>} />
            <Route exact path='/view/:id' element={<Alumus PF={PF}/>} />       
          </Routes>
          <RightSideBar PF={PF} />
        </div>
      </div>
    </div>
  )
}

export default Alum