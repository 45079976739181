import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import axios from '../Assets/axios';

const VIEW_BOTTOMBAR_URL = '/home/bottombar';

function BottomBar({PF}) {
    const [bottomNews, setBottomNews] = useState([]);  
    const [bottomArticle, setBottomArticle] = useState([]);  
    const [bottomProfile, setBottomProfile] = useState([]);
    const [bottomPictures, setBottomPictures] = useState([]);
    
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        axios.get(VIEW_BOTTOMBAR_URL).then((response)=>{
            if(isMounted){
                setBottomNews(response.data.story);
                setBottomArticle(response.data.article);
                setBottomProfile(response.data.profile);
                setBottomPictures(response.data.pictures);
            }
        }).catch((error) => {
            //Handle Errors Coming out of this
        });
        return () => { isMounted = false; controller.abort(); }
    }, []);
    
    return (
        <>
            <div className="container-fluid bg-red pt-5 mt-5">
                <div className="row py-4">
                    <div className="col-lg-3 col-md-6 mb-5">
                        <h5 className="mb-4 text-white text-uppercase font-weight-bold">Get In Touch</h5>
                        <p className="font-weight-medium"><i className="fa fa-map-marker-alt mr-2"></i>Plot 1048 Kikulu Zone, Kisaasi</p>
                        <p className="font-weight-medium"><i className="fa fa-mail-bulk mr-2"></i>P. O Box 7121, Kampala, Uganda</p>
                        <p className="font-weight-medium"><i className="fa fa-phone-alt mr-2"></i>+256 414 699 048</p>
                        <p className="font-weight-medium"><i className="fa-brands fa-whatsapp mr-2"></i>+256 707 412 681</p>
                        <p className="font-weight-medium"><i className="fa fa-envelope mr-2"></i><Link to='mailto:administrator@budonian.com'>administrator@budonian.com</Link></p>
                        <h6 className="mt-4 mb-3 text-white text-uppercase font-weight-bold">Follow Us</h6>
                        <div className="d-flex justify-content-start">
                            <a target="_blank" rel="noreferrer" className="btn btn-lg btn-secondary btn-lg-square mr-2" href="https://www.twitter.com/budonian"><i className="fab fa-twitter"></i></a>
                            <a target="_blank" rel="noreferrer" className="btn btn-lg btn-secondary btn-lg-square mr-2" href="https://www.facebook.com/The-Old-Budonians-Club-1533385500286920"><i className="fab fa-facebook-f"></i></a>
                            <a target="_blank" rel="noreferrer" className="btn btn-lg btn-secondary btn-lg-square mr-2" href="https://www.linkedin.com/in/administrator-old-budonians-club-295aa8261"><i className="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-5">
                        <h5 className="mb-4 text-white text-uppercase font-weight-bold">Popular</h5>
                        {bottomNews.map((story, index) =>{return(
                            <div className="mb-3" key={index}>
                                <div className="mb-2">
                                    <Link className="badge badge-primary text-uppercase font-weight-semi-bold p-1 mr-2" to="/news">News</Link>
                                    <small className="text-body">{new Date(story.createdAt).toDateString()}</small>
                                </div>
                                <Link className="small text-body text-uppercase font-weight-medium" to={`/news/view/${story.id}`}>{story.title}</Link>
                            </div>    
                        )})}
                        {bottomArticle.map((article, index)=>{return(
                            <div className="mb-3" key={index}>
                                <div className="mb-2">
                                    <Link className="badge badge-primary text-uppercase font-weight-semi-bold p-1 mr-2" to="/articles">Articles</Link>
                                    <small className="text-body">{new Date(article.updatedAt).toDateString()}</small>
                                </div>
                                <Link className="small text-body text-uppercase font-weight-medium" to={`/articles/view/${article.id}`}>{article.title}</Link>
                            </div>
                        )})}
                        {bottomProfile.map((profile, index)=>{return(
                            <div className="mb-3" key={index}>
                                <div className="mb-2">
                                    <Link className="badge badge-primary text-uppercase font-weight-semi-bold p-1 mr-2" to="/profiles">Profiles</Link>
                                    <small className="text-body">{new Date(profile.updatedAt).toDateString()}</small>
                                </div>
                                <Link className="small text-body text-uppercase font-weight-medium" to={`/profiles/view/${profile.id}`}>{profile.profileTitle}</Link>
                            </div>
                        )})}
                    </div>
                    <div className="col-lg-3 col-md-6 mb-5">
                        <h5 className="mb-4 text-white text-uppercase font-weight-bold">From Photo Gallery</h5>
                        <div className="row">
                            {bottomPictures.length>0 && bottomPictures.map((image)=>{
                                return(
                                    <div className="col-4 mb-3" key={image.id}>
                                        <Link to={`/gallery/view/${image.galleryId}`}><img className="w-100" src={PF + image.picture} alt="" /></Link>
                                    </div>    
                                )
                            })}
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-5">
                        <h5 className="mb-4 text-white text-uppercase font-weight-bold">Newsletter</h5>
                        <div className="mb-3">
                            <div className="text-white text-center">
                                <p>Sign up to our monthly newsletter and receive all the important posts in your email</p>
                                <div className="input-group mb-2" style={{width: '100%'}}>
                                    <input type="text" className="form-control form-control-lg" placeholder="Your Email" />
                                    <div className="input-group-append">
                                        <button className="btn btn-primary font-weight-bold px-3">Sign Up</button>
                                    </div>
                                </div>
                                <small>Trending Posts and Editor's pick</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid py-0 px-0 bg-red">
                <p className="m-0 text-center">
                    &copy; 
                    <small> Old Budonians' Club. 
                    All Rights Reserved.     
                    Design by <Link to="https://htmlcodex.com">HTML Codex</Link></small>
                </p>
            </div>
        </>
    )
}

export default BottomBar