import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import {Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useAuth from '../../Hooks/useAuth';
import jwtDecode from 'jwt-decode';

const VIEW_GALLERIES_URL = '/galleries';

function Galleries({PF}) {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
  const [galleries, setGalleries] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSet, setPageSet] = useState(0);
  const [page, setPage] = useState(1); 

  const navigate = useNavigate();  
  
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    axiosPrivate.get(VIEW_GALLERIES_URL + '?page=' + page).then((response) => {
      if(isMounted){
        setGalleries(response.data.docs);
        setTotalPages(response.data.pages);
      }
    }).catch((error) => {
      //Handle Errors Coming out of this
    });
    return () => { isMounted = false; controller.abort();  }
    // eslint-disable-next-line
  }, [page]);

  let pages = [];
  const pageSets = Math.ceil(totalPages/5); //Identify how many sets of 5 to display
  for(let i = (pageSet*5); i<totalPages; i++){ //determine page numbers to display when << previous or >> next buttons are clicked
      pages.push(i+1); 
  }
 
  return (
    <div className="col-lg-8">
      <div className="row">
        <div className="col-12">
          <div className="section-title">
            <h4 className="m-0 text-uppercase font-weight-bold">Photo Galleries</h4>
            {(authDecode && authDecode?.user?.role >=3) && 
              <Link className="text-secondary font-weight-medium text-decoration-none" to="/gallery/add">
                <i className="writeIcon fas fa-plus"></i> Add Photo Gallery
              </Link>
            }
          </div>
        </div>
        { galleries.map((value)=>{
          return (
              <div key={value.id} className='col-lg-4 position-relative mb-3' onClick={() => navigate(`/gallery/view/${value.id}`)}>
                <img className="img-fluid w-100" src={PF + value.picture} alt="" />
                <div className="bg-white border border-top-0 p-2">
                  <div className="mb-2">
                    <Link className="text-body" to="/"><small>{new Date(value.createdAt).toDateString()}</small></Link>
                  </div>
                  <Link className="h6 d-block mb-3 text-secondary text-capitalize font-weight-bold paraLimit" to={`/gallery/view/${value.id}`}>{value.name}</Link>
                  <p className="m-0 paraLimit">{value.description}</p>
                </div>
              </div>
          );
        })}
        {totalPages>1 &&
          <div className="col-lg-12">
              <nav className="pagination justify-content-center mt-50">
                  <ul className="pagination">
                      {pageSet>0 &&
                          <li className="page-link">
                              <Link to="#previous" aria-label="Previous" onClick={(e)=>{setPageSet(pageSet-1)}}>
                                  <i className="fa fa-angle-left"></i>
                              </Link>
                          </li>                                                
                      }
                      {
                          pages.slice(0,5).map((pageNum, index) => {return <li className="page-item" key={index}><Link className="page-link" to={`#page=${pageNum}`} onClick={(e)=>setPage(pageNum)}>{pageNum}</Link></li>})
                      }
                      {(pageSet+1)<pageSets && 
                          <li className="page-item">
                              <Link className='page-link' to="#next" aria-label="Next" onClick={(e)=>{setPageSet(pageSet+1)}}>
                                  <i className="fa fa-angle-right"></i>
                              </Link>
                          </li>                                                    
                      }

                  </ul>
              </nav>  { /*<!-- courses pagination --> */}
          </div>
        }
      </div>
    </div>
  )
}

export default Galleries