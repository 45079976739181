import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import { Link, useNavigate } from 'react-router-dom';
import useAuth from '../../Hooks/useAuth';
import { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';

const VIEW_TOPPROFILES_URL = '/profiles/top';

function TopProfiles({PF}) {
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
    const [topProfiles, setTopProfiles] = useState([]);
    const navigate = useNavigate();  
  
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController(); 
        axiosPrivate.get(VIEW_TOPPROFILES_URL).then((response) => {
           isMounted && setTopProfiles(response.data.top);
        }).catch((error) => {
            //Handle Errors Coming out of this
        });
        return () => { isMounted = false; controller.abort();  }
        // eslint-disable-next-line
    }, []);
    
    return (
        <div className="container">
            <div className="section-title col-12">
                <h4 className="m-0 text-uppercase font-weight-bold">Alumni Profiles</h4>
                {(authDecode && authDecode?.user?.role >=3) && 
                    <Link className="text-secondary font-weight-medium text-decoration-none" to="/profiles/add">
                        <i className="writeIcon fas fa-plus"></i> Add Profile
                    </Link>
                }
            </div>
            { topProfiles.length>0 &&
                <div className="row mx-0 mb-3">
                    <div className="col-lg-7 position-relative overflow-hidden px-0" style={{height: "500px"}} onClick={() => navigate(`/profiles/view/${topProfiles[0].id}`)}>
                        <img className="img-fluid h-100" src={PF + topProfiles[0].pictures} style={{objectFit: "cover"}} alt="" />
                        <div className="overlay">
                            <Link className="h6 m-0 text-white text-uppercase font-weight-semi-bold" to={`/profiles/view/${topProfiles[0].id}`}>{topProfiles[0].profileTitle}</Link>
                            <p className="m-0 paraLimit" dangerouslySetInnerHTML={{ __html: topProfiles[0].profileText}} />
                        </div>
                    </div>
                    <div className='col-lg-5 row'>
                        <div className="col-lg-6 position-relative overflow-hidden px-0" style={{height: "500px"}} onClick={() => navigate(`/profiles/view/${topProfiles[1].id}`)}>
                            <img className="img-fluid h-100" src={PF + topProfiles[1].pictures} style={{objectFit: "cover"}} alt="" />
                            <div className="overlay">
                                <Link className="h6 m-0 text-white text-uppercase font-weight-semi-bold" to={`/profiles/view/${topProfiles[1].id}`}>{topProfiles[1].profileTitle}</Link>
                                <p className="m-0 paraLimit" dangerouslySetInnerHTML={{ __html: topProfiles[1].profileText}} />
                            </div>
                        </div>

                        <div className="col-lg-6 position-relative overflow-hidden px-0" style={{height: "500px"}} onClick={() => navigate(`/profiles/view/${topProfiles[2].id}`)}>
                            <img className="img-fluid h-100" src={PF + topProfiles[2].pictures} style={{objectFit: "cover"}} alt="" />
                            <div className="overlay">
                                <Link className="h6 m-0 text-white text-uppercase font-weight-semi-bold" to={`/profiles/view/${topProfiles[2].id}`}>{topProfiles[2].profileTitle}</Link>
                                <p className="m-0 paraLimit" dangerouslySetInnerHTML={{ __html: topProfiles[2].profileText}} />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default TopProfiles