import {Link } from 'react-router-dom';
import { useState } from 'react'

const Pagination = ({totalPages, setPage}) => {
    const [pageSet, setPageSet] = useState(0);
    
    let pages = [];
    const pageSets = Math.ceil(totalPages/5); //Identify how many sets of 5 to display
    for(let i = (pageSet*5); i<totalPages; i++){ //determine page numbers to display when << previous or >> next buttons are clicked
        pages.push(i+1); 
    }

    return (
        <div className="col-lg-12">
            <nav className="pagination justify-content-center mt-50">
                <ul className="pagination">
                    {pageSet>0 &&
                        <li className="page-link">
                            <Link to="#previous" aria-label="Previous" onClick={(e)=>{setPageSet(pageSet-1)}}>
                                <i className="fa fa-angle-left"></i>
                            </Link>
                        </li>                                                
                    }
                    {
                        pages.slice(0,5).map((pageNum, index) => {return <li className="page-item" key={index}><Link className="page-link" to={`#page=${pageNum}`} onClick={(e)=>setPage(pageNum)}>{pageNum}</Link></li>})
                    }
                    {(pageSet+1)<pageSets && 
                        <li className="page-item">
                            <Link className='page-link' to="#next" aria-label="Next" onClick={(e)=>{setPageSet(pageSet+1)}}>
                                <i className="fa fa-angle-right"></i>
                            </Link>
                        </li>                                                    
                    }

                </ul>
            </nav>  { /*<!-- courses pagination --> */}
        </div>
    )
}

export default Pagination
