import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import Pagination from '../../Components/Pagination';
import { useNavigate, Link} from 'react-router-dom';
import { useState, useEffect } from 'react';
import useAuth from '../../Hooks/useAuth';
import jwtDecode from 'jwt-decode';

const DELETE_EVENT_URL = '/events/delete/';
const DELETE_FILE_URL = '/fileDelete/';
const VIEW_EVENTS_URL = '/events';

function ViewEvents({PF}) {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;

  const [calEvents, setCalEvents] = useState([]);
  const [calEventPages, setCalEventPages] = useState(0);
  const [pastEvents, setPastEvents] = useState([]);
  const [pastEventPages, setPastEventPages] = useState(0);
  const [eventNumber, setEventNumber] = useState(0);
  const [pastEventNumber, setPastEventNumber] = useState(0);
  const [openEvent, setOpenEvent] = useState(false); 
  const [openPastEvent, setOpenPastEvent] = useState(false); 
  const [calPage, setCalPage] = useState(1);
  const [pastPage, setPastPage] = useState(1);
  const navigate = useNavigate();
  
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    axiosPrivate.get(VIEW_EVENTS_URL + '?page=' + calPage + '&pastPage='+ pastPage).then((response)=>{
      if(isMounted){
        setCalEvents(response.data.calEvents.docs);
        setCalEventPages(response.data.calEvents.pages);
        setPastEvents(response.data.pastEvents.docs);
        setPastEventPages(response.data.pastEvents.pages);
        
      }
    }).catch((error) => {
      //Handle Errors Coming out of this
  });
    return () => { isMounted = false; controller.abort();  }
    // eslint-disable-next-line
  }, [calPage, pastPage]);

  // const handleOpenEvent = (index) => {
  //   setEventNumber(index)
  //   setOpenEvent(true)
  // }

  const deleteCalEvent = async(id) => {
    let eventPic = '';
    for(let i=0; i<calEvents.length; i++){ 
      if(calEvents[i].id === id){ eventPic = calEvents[i].picture; }
      console.log(eventPic);
    }
    try {
      axiosPrivate.post(DELETE_FILE_URL + eventPic).then(()=>{
        console.log('Event Picture Deleted');
      }).catch(function(error){ console.log(error.config); }); 
      axiosPrivate.delete(DELETE_EVENT_URL + id).then(()=>{ 
        alert('Calendar Event Deleted'); 
        navigate('/events');
      });               
    }catch(error) { }
  };

  return (
    <div className="col-lg-8 mb-3">
      <div className="section-title">
        <h4 className="m-0 text-uppercase font-weight-bold">Upcoming Events</h4>
        {(authDecode && authDecode?.user?.role >=3) && 
          <Link className="text-secondary font-weight-medium text-decoration-none" to="/events/add">
            <i className="writeIcon fas fa-plus"></i> Add Calendar Event
          </Link>
        }
      </div>
      { calEvents && calEvents.map((calEvent, index) => {
        return (
          <div key={index}>
            { openEvent && eventNumber === index ? (
              <div key={calEvent.id} className='row mb-5 position-relative'>
                <div className="col-lg-5">
                  <img className='img-fluid' src={PF + calEvent.picture} alt='' />
                  <div className="slideIcons">
                    {authDecode && (authDecode?.user?.id === calEvent.UserId || authDecode?.user?.role >=4) ? (<>
                      <Link to={`/events/edit/${calEvent.id}`}><i className="singleIcon far fa-edit mr-2" aria-hidden="true"/></Link>
                      <i className="singleIcon far fa-trash-alt" aria-hidden="true" onClick={() => deleteCalEvent(calEvent.id)} />
                    </>) : (<></>)}
                  </div>
                </div>
                <div className='col-lg-7'>
                  <h5>{calEvent.title}</h5>
                  <b>Date:</b> {new Date(calEvent.date).toDateString()} <br />
                  <b>Location:</b> {calEvent.location}<hr />
                  <p dangerouslySetInnerHTML={{ __html: calEvent.description}} style={{whiteSpace: "pre-line"}} />
                </div>
              </div>
            ) : (
              <div key={calEvent.id} className="d-flex align-items-center bg-white mb-3" style={{height: '110px'}} onClick={() =>{setEventNumber(index); setOpenEvent(true);}}>
                <img className="img-fluid" src={PF + calEvent.picture} style={{height: '110px'}} alt="" />
                <div className="w-100 h-100 px-3 d-flex flex-column justify-content-center border border-left-0">
                  <div className="mb-2">
                    <Link className="badge badge-primary text-uppercase font-weight-semi-bold p-1 mr-2" to="/">{calEvent.location}</Link><br />
                    <small className="text-body">{new Date(calEvent.date).toDateString()}</small>
                  </div>
                  <small className="h6 m-0 text-secondary text-capitalize font-weight-bold">{calEvent.title}</small>
                </div>
              </div>
            )}
          </div>
        )
      })}
      {calEventPages>1 &&
        <Pagination totalPages={calEventPages} setPage={setCalPage}/>
      }
      <div className="section-title">
        <h4 className="m-0 text-uppercase font-weight-bold">Past Events</h4>
      </div>
      { pastEvents && pastEvents.map((pastEvent, index) => {
        return (
          <div key={index}>
            { openPastEvent && pastEventNumber === index ? (
              <div key={pastEvent.id} className='row mb-5 position-relative'>
                <div className="col-lg-5">
                  <img className='img-fluid' src={PF + pastEvent.picture} alt='' />
                </div>
                <div className='col-lg-7'>
                  <h5>{pastEvent.title}</h5>
                  <b>Date:</b> {new Date(pastEvent.date).toDateString()} <br />
                  <b>Location:</b> {pastEvent.location}<hr />
                  <p dangerouslySetInnerHTML={{ __html: pastEvent.description}} style={{whiteSpace: "pre-line"}} />
                </div>
              </div>
            ) : (
              <div key={pastEvent.id} className="d-flex align-items-center bg-white mb-3" style={{height: '110px'}} onClick={() => {setPastEventNumber(index); setOpenPastEvent(true);}}>
                <img className="img-fluid" src={PF + pastEvent.picture} style={{height: '110px'}} alt="" />
                <div className="w-100 h-100 px-3 d-flex flex-column justify-content-center border border-left-0">
                  <div className="mb-2">
                    <Link className="badge badge-primary text-uppercase font-weight-semi-bold p-1 mr-2" to="/">{pastEvent.location}</Link><br />
                    <small className="text-body">{new Date(pastEvent.date).toDateString()}</small>
                  </div>
                  <small className="h6 m-0 text-secondary text-capitalize font-weight-bold">{pastEvent.title}</small>
                </div>
              </div>
            )}
          </div>
        )
      })}
      {pastEventPages>1 &&
        <Pagination totalPages={pastEventPages} setPage={setPastPage}/>
      }
    </div>
  )
}

export default ViewEvents
