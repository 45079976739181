import { Link } from "react-router-dom"

function HomeArticles({articles, PF}) {
    return (
        <>
            { articles.length>0 && 
                <>
                <div className="col-12">
                    <div className="section-title">
                        <h4 className="m-0 text-uppercase font-weight-bold">Latest Articles</h4>
                        <Link className="text-secondary font-weight-medium text-decoration-none" to="/articles">View All</Link>
                    </div>
                </div>
                { articles.map((article) =>{
                    return (
                    <div key={article.id} className="col-lg-4">
                        <div className="position-relative mb-3">
                            <img className="img-fluid w-100" src={PF + article.picture} style={{objectFit: 'cover'}} alt="" />
                            <div className="bg-white border border-top-0 p-2">
                            <div className="mb-2">
                                <small>{new Date(article.updatedAt).toDateString()}</small>
                            </div>
                            <Link className="h5 d-block mb-3 text-secondary text-capitalize font-weight-bold" to={`/articles/view/${article.id}`}>{article.title}</Link>
                            </div>
                        </div>
                    </div>
                    )
                })}
                </>
            }
        </>
    )
}

export default HomeArticles
