
import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import {Link, useNavigate } from 'react-router-dom';
import useAuth from '../../Hooks/useAuth';
import {useState, useEffect} from 'react';
import jwtDecode from 'jwt-decode';

const TOP_ARTICLES_URL = '/articles/top';


function LatestArticlesGrid({PF}) {
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
    const [topArticle, setTopArticle] = useState({});
    const [articles, setArticles] = useState([]);
    const navigate = useNavigate();  

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        axiosPrivate.get(TOP_ARTICLES_URL).then((response) => {
            if(isMounted){
                setTopArticle(response.data.main);
                setArticles(response.data.others);      
            }
        }).catch((error) => {
            //Handle Errors Coming out of this
        });
        return () => { isMounted = false; controller.abort(); }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="container">
            <div className="section-title">
                <h4 className="m-0 text-uppercase font-weight-bold">Latest Articles</h4>
                {(authDecode && authDecode?.user?.role >=3) && 
                    <Link className="text-secondary font-weight-medium text-decoration-none" to="/articles/add">
                        <i className="writeIcon fas fa-plus"></i> Add Article
                    </Link>                
                }
            </div>
            <div className="row mx-0 mb-3">
                {topArticle?.picture &&
                    <div className="col-lg-6 position-relative overflow-hidden px-0" style={{height: "500px"}} onClick={() => navigate(`/articles/view/${topArticle.id}`)}>
                        <img className="img-fluid h-100" src={PF + topArticle.picture} style={{objectFit: "cover"}} alt="" />
                        <div className="overlay">
                            <div className="mb-2">
                                <Link className="text-white" to="/"><small>{new Date(topArticle.updatedAt).toDateString()}</small></Link>
                            </div>
                            <Link className="h6 m-0 text-white text-uppercase font-weight-semi-bold" to={`/articles/view/${topArticle.id}`}>{topArticle.title}</Link>
                        </div>
                    </div>
                 }
                <div className="col-lg-6 px-0">
                    <div className="row mx-0">
                        { articles.map((value)=>{
                            return (
                                <div key={value.id} className="col-lg-6 px-0 position-relative overflow-hidden" style={{height: "250px"}} onClick={() => navigate(`/articles/view/${value.id}`)}>
                                    <img className="img-fluid w-100 h-100" src={PF + value.picture} style={{objectFit: 'cover'}} alt="" />
                                    <div className="overlay">
                                        <div className="mb-2">
                                            <Link className="text-white" to="/"><small>{new Date(value.updatedAt).toDateString()}</small></Link>
                                        </div>
                                        <Link className="h6 m-0 text-white text-capitalize font-weight-semi-bold" to={`/articles/view/${value.id}`}>{value.title}</Link>
                                    </div>
                                </div>
                            );
                        })}  
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LatestArticlesGrid
